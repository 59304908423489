import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import styled from "styled-components"
import { Link } from "gatsby"
import { slugify } from "@utils/slugify"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import theme from "../../styles/theme"

const handleTileVariation = number => {
  // If number is greater than 5, bring it down to the 1-5 range.
  const adjustedNum = number > 5 ? number - Math.floor(number / 5) * 5 : number

  if (adjustedNum % 5 === 0) {
    return `linear-gradient(180deg,#7ea5f0 16.75%,#6484c0 100%)`
  } else if (adjustedNum % 4 === 0) {
    return `linear-gradient(180deg,#6551aa 16.75%,#504088 100%)`
  } else if (adjustedNum % 3 === 0) {
    return `linear-gradient(180deg,#d35638 16.75%,#a8442c 100%)`
  } else if (adjustedNum % 2 === 0) {
    return `linear-gradient(180deg,#efc936 16.75%,#bfa02b 100%)`
  } else {
    return `linear-gradient(180deg,#32ada8 16.75%,#288a86 100%)`
  }
}

const MetricStackTile = styled(Link)`
  border-radius: 1.5rem;
  overflow: hidden;
  grid-column: span 1;
  cursor: pointer;
  background: ${({ number }) => handleTileVariation(number)};
  position: relative;
  transition: filter 0.3s ease;
  filter: drop-shadow(0px 0px 1rem rgba(0, 0, 0, 0.16));

  &:hover {
    filter: drop-shadow(0px 0px 2rem rgba(0, 0, 0, 0.48));
  }

  :after {
    content: "";
    display: block;
    padding-bottom: 50%;
  }

  .klipfolio-image-wrapper {
    opacity: 0.2;
    margin-top: 0 !important;
    border-radius: 1.5rem;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    img {
      height: 100%;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }

  span {
    color: white;
    margin-bottom: 0;
  }

  span {
    font-size: 1.2rem;
    font-weight: 700;
  }
`

const MetricStackTileInner = styled.div`
  z-index: 1;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
`

const MetricStackAbout = styled.div`
  border-radius: 1.5rem;
  grid-column: span 3;
  max-width: 900px;
  text-align: center;
  margin: 2rem auto;
`

const MetricStackArchive = ({ data, pageContext }) => {
  const episodes = data.episodes.edges
  const cover = data.image

  return (
    <Layout
      image={cover?.cdn}
      title={"Metric Stack Podcast"}
      description={
        "Hosted by Allan Wille and Lauren Thibodeau, the Metric Stack podcast is the place to hear stories from founders, leaders, marketers, and more as they share how they succeed with data."
      }
      fullWidth
      canonical={pageContext.slug}
      hideOverflow
    >
      <Container>
        <MetricStackAbout>
          <Image
            alt="Metric Stack Cover Artwork"
            radius={1}
            file={cover}
            height={300}
            width={300}
            style={{ margin: "0 auto" }}
            borderStyle={{ boxShadow: theme.shadow.default }}
            objectFit="scale-down"
          />
          <Heading margin="2rem 0 1rem">Metric Stack Podcast</Heading>
          <Heading as="h4" margin="0 0 4rem" lineHeight="1.5">
            Hosted by Allan Wille and Lauren Thibodeau, the Metric Stack podcast
            is the place to hear stories from founders, leaders, marketers, and
            more as they share how they succeed with data.
          </Heading>
        </MetricStackAbout>
        <Grid
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
          gap="2rem"
          columns="repeat(auto-fill, minmax(300px, 1fr))"
        >
          {episodes.map(({ node: episode }, i) => {
            const slug = `/metric-stack/episode/${
              episode.episode_number
            }/${slugify(episode.guest_name)}`
            return (
              <MetricStackTile
                key={`metric-stack-episode-${i}`}
                number={episode.episode_number}
                to={slug}
              >
                <MetricStackTileInner>
                  <span>Episode {episode.episode_number}</span>
                  <div>
                    <Heading as="h3" color="white" margin="0 0 1rem">
                      {episode.title}
                    </Heading>
                    <hr />
                    <Heading as="h4" color="white" margin="0 0 0.25rem">
                      With {episode.guest_name}
                    </Heading>
                    <Heading as="h5" color="white">
                      {episode.job_title}
                    </Heading>
                  </div>
                </MetricStackTileInner>
                {episode.guest_image && <Image file={episode.guest_image} />}
              </MetricStackTile>
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

MetricStackArchive.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default MetricStackArchive

export const pageQuery = graphql`
  query MetricStackArchiveQuery {
    image: directusFiles(
      directus_id: { eq: "d9fdc2f2-6c1d-433e-847b-4787c17b0007" }
    ) {
      cdn
      placeholder
      title
    }
    episodes: allMetricStackEpisode(
      filter: { status: { eq: "published" } }
      sort: { fields: episode_number, order: DESC }
    ) {
      edges {
        node {
          guest_name
          guest_image {
            cdn
            placeholder
            title
          }
          title
          episode_number
          job_title
        }
      }
    }
  }
`
